import { LoadingOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import { ASSIST_MAIL, urlParams } from "../constants/AppConstants";

import { Badge, Button, Col, Divider, Drawer, Dropdown, Image, Menu, Row, Select, Spin, Table, Tag } from "antd";
import "../assets/css/omessage.css";
import EmtpyBoxSVG from "../assets/svg/empty-box .svg";
import { AppContext } from "../context/AppContext";
import useUsers from "../hooks/useUsers";
import "./../assets/css/users.css";
import { DeleteComponent } from "./utils/CommonVessels";

import {
  failureNotification,
  failureNotificationWithLink,
  successNotifyWithDescription,
  failureNotificationWithDuration15Seconds,
  failurePricingNotificationWithBtn,
} from "./utils/CommonNotifications";
import { CommonLoading, formatDate } from "./utils/CommonVessels";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault("local");

function Users() {
  const [appProperties] = useContext(AppContext);
  const [show, setShow] = useState(true);
  const { fetchCurrentUsers, deleteUser } = useUsers();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [savedUsers, setSavedUsers] = useState([]);
  const [callSavedUserTable, setCallSavedUserTable] = useState(true);

  let [userId, setUserId] = useState("");
  const [, setUserModal] = useState("");

  const [openPopover, setOpenPopover] = useState({});
  const [showTable, setShowTable] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [totalPurchasedUser, setTotalPurchasedUser] = useState(0);
  const [activeUsers, setActiveUsers] = useState(0);
  const [status, setStatus] = useState();
  const [planName, setPlanName] = useState();
  const [users, setUsers] = useState([]);
  const [visibleDropdown, setVisibleDropdown] = useState(null);

  const [responseObj, setResponseObj] = useState();

  const getUsers = () => {
    setIsDrawerOpen(true);
    setUserId("");
    setUserModal("AddUsers");
  };

  const deleteUsers = (userData) => {
    console.log("userData>>>>>", userData);
    userId = userData.remoteUserId;
    let payload = {
      leftServiceId: appProperties.leftServiceId,
      rightServiceId: appProperties.rightServiceId,
      osyncUserId: userData.osyncUserId,
    };
    deleteUser(appProperties, payload).then((response) => {
      console.log(response, "deleted user response");
      if (response?.status === 200) {
        appProperties.licenseObj = response.data;
        setResponseObj(response);
        setCallSavedUserTable(true);
        if (response?.data?.status === "failed") {
          failureNotificationWithLink("At least one user must be retained; deletion is restricted.");
        } else {
          successNotifyWithDescription("Delete User", "User deleted. Feel free to add a new one when needed.");
        }
      }
      setOpenPopover((prevOpenPopover) => ({
        ...prevOpenPopover,
        [userId]: false,
      }));
    });
  };
  const handlePopOpen = (userId, newOpen) => {
    setOpenPopover((prevOpenPopover) => ({
      ...prevOpenPopover,
      [userId]: newOpen,
    }));
  };
  let urlParams = new URLSearchParams(window.location.search);
  userId = urlParams.get("userId");

  const handleMenuClick = (e, record) => {
    setVisibleDropdown(visibleDropdown === record.remoteUserId ? null : record.remoteUserId);
  };

  const menu = (record) => <UserDelete record={record} />;

  const UserDelete = (props) => {
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const { record } = props;

    const handleDeleteClick = () => {
      setDeleteModalOpen(true);
      setVisibleDropdown(null); // Close the dropdown when opening the modal
    };

    return (
      <>
        <Menu>
          <Menu.Item key="deletetemplate" onClick={() => handleDeleteClick(record)}>
            <div className="actionIconsSprite deleteTemplate-icon">
              <span className="ps-4" style={{ fontSize: "12px" }}>
                Delete
              </span>
            </div>
          </Menu.Item>
        </Menu>
        <DeleteComponent
          modalOpen={deleteModalOpen}
          setModalOpen={setDeleteModalOpen}
          record={record}
          confirmDeleteComponent={"users"}
          appProperties={appProperties}
          handleDelete={deleteUsers}
        />
      </>
    );
  };
  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "6rem",
    },
    // {
    //   title: isOmniAccount ? "OsyncId" : "Org Account ID",
    //   dataIndex: isOmniAccount ? "osyncId" : "accountOrgId",
    //   key: isOmniAccount ? "osyncId" : "accountOrgId",
    //   width: "27rem",
    // },
    // {
    //   title: isOmniAccount ? "RemoteUserId" : "Remote Identifier",
    //   dataIndex: isOmniAccount ? "remoteUserId" : "remoteId",
    //   key: isOmniAccount ? "remoteUserId" : "remoteId",
    // },
    {
      title: "Created Time",
      key: "createdTime",
      width: "20rem",
      dataIndex: "createdTime",
      render: (text) => {
        return <div>{formatDate(text)}</div>;
      },
    },
    {
      key: "deleteusers",
      render: (record) => (
        <>
          {record.remoteUserId !== (appProperties.userId || userId) &&
          appProperties.extensionInstalledUserEmailId &&
          record.email !== appProperties.extensionInstalledUserEmailId &&
          activeUsers > 0 ? (
            <Dropdown
              rootClassName="editDeleteSettingDropdown"
              overlay={menu(record)}
              trigger={["click"]}
              visible={visibleDropdown === record.remoteUserId}
              onVisibleChange={() => handleMenuClick(null, record)}
            >
              <div className="actionIconsSprite  editDeleteSettingsIcon"></div>
            </Dropdown>
          ) : (
            ""
          )}
        </>
      ),
      width: "5%",
    },
  ];

  useEffect(() => {
    if (callSavedUserTable) {
      let numberOfActiveUsers = 0;
      let licensedUserDetails = null;

      if (appProperties?.licenseObj) {
        licensedUserDetails = appProperties?.licenseObj?.allLicensedUserDetails;
      } else if (responseObj?.data) {
        licensedUserDetails = responseObj?.data?.allLicensedUserDetails;
      }
      if (licensedUserDetails) {
        setShowTable(true);
        setSavedUsers(licensedUserDetails);
        numberOfActiveUsers = licensedUserDetails.length ?? 0;
      }
      setActiveUsers(numberOfActiveUsers);
      setTotalPurchasedUser(appProperties?.licenseObj?.licenseDetails?.totalUsersPurchased);
      setStatus(appProperties?.licenseObj?.licenseDetails?.licenseStatus);
      setPlanName(appProperties?.licenseObj?.licenseDetails?.licensePlanName);
      setCallSavedUserTable(true);
      setShowLoading(false);
    }

    let showAddUser = urlParams.get("showAddUser");
    if (showAddUser && showAddUser === "true") {
      setIsDrawerOpen(true);

      const url = new URL(window.location.href);

      // Get the search parameters
      const params = new URLSearchParams(url.search);

      params.delete("showAddUser");

      // Change the endpoint to '/users'
      const newEndpoint = "/users";

      // Construct the new URL
      const newUrl = `${url.origin}${newEndpoint}?${params.toString()}`;

      // Update the URL in the browser without reloading
      window.history.pushState({}, "", newUrl);
    }
  }, [appProperties, callSavedUserTable, responseObj]);

  return (
    <>
      <div className="m-1, mt-3 ms-2">
        <Row align="middle">
          <Col span={9} className="ps-4">
            <div className="d-flex justify-content-start align-items-center">
              <Row className="mt-2">
                <Col style={{ cursor: "default" }} className="actionIconsSprite authInfo-icon"></Col>
                <Col className="ms-2 contentbarHeadingColor">Manage user access for seamless customer interactions.</Col>
              </Row>
            </div>
          </Col>
          <Col span={11}>
            <Row align="middle">
              <Col span={24}>
                <div className="d-flex justify-content-end">
                  <Tag
                    style={{
                      backgroundColor: "#fff",
                      padding: 8,
                      border: 0,
                      borderRadius: 50,
                    }}
                  >
                    Total purchased users
                    <Badge
                      style={{
                        backgroundColor: "#D9D8FF",
                        color: "#605BFF",
                        marginLeft: 5,
                      }}
                      count={totalPurchasedUser}
                    ></Badge>
                  </Tag>
                  <Tag
                    style={{
                      backgroundColor: "#fff",
                      padding: 8,
                      marginLeft: 5,
                      border: 0,
                      borderRadius: 50,
                    }}
                  >
                    Active users
                    <Badge
                      style={{
                        backgroundColor: "#D9D8FF",
                        color: "#605BFF",
                        marginLeft: 5,
                      }}
                      count={activeUsers ? activeUsers : 0}
                    ></Badge>
                  </Tag>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={4}>
            <div
              className="d-flex justify-content-center align-items-center
              "
            >
              <Button className="addTempBtn" type="" onClick={getUsers} icon={<div className="actionIconsSprite plusIconWhite" />}>
                Add Users
              </Button>
            </div>
          </Col>
        </Row>

        {isDrawerOpen ? (
          <AddUsers
            setIsDrawerOpen={setIsDrawerOpen}
            isDrawerOpen={isDrawerOpen}
            appProperties={appProperties}
            setCallSavedUserTable={setCallSavedUserTable}
            users={users}
            setUsers={setUsers}
            setResponseObj={setResponseObj}
          />
        ) : null}

        {showLoading ? (
          <CommonLoading />
        ) : showTable ? (
          <Table
            className="savedTempTable automationTable ms-3"
            scroll={{ x: 1100, y: 520 }}
            pagination={false}
            columns={columns}
            dataSource={savedUsers}
          />
        ) : (
          <div className="d-flex justify-content-center">
            <Image style={{ width: "10rem", marginTop: "5rem" }} src={EmtpyBoxSVG} preview={false} />
          </div>
        )}
      </div>
    </>
  );
}

export default Users;

const AddUsers = React.memo((props) => {
  const { isDrawerOpen, setIsDrawerOpen, appProperties, setCallSavedUserTable, users, setUsers, setResponseObj } = props;

  const [drawerOpen, setDrawerOpen] = useState(isDrawerOpen);

  const { fetchUsers, addUsers } = useUsers();

  const [selectedUser] = useState();
  const [usersList, setUsersList] = useState([]);
  const userListArray = new Set(usersList.map((tag) => tag.value));
  const [loadingUserResponse, setLoadingUserResponse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (appProperties) {
      setLoadingUserResponse(true);
      fetchUsers(appProperties).then((response) => {
        if (response !== null) {
          setLoadingUserResponse(false);
          let usersList = response.data;
          setUsers(usersList);
        }
      });
    }
  }, [appProperties]);

  const addUser = () => {
    setLoading(true);
    const addedUser = usersList.map((item) => `${item.userId}::${item.value}`);
    if (usersList.length > 0) {
      let payload = {
        leftServiceId: appProperties.leftServiceId,
        rightServiceId: appProperties.rightServiceId,
        remoteIdentifier: urlParams.get("companyId"),
        users: addedUser,
      };
      addUsers(appProperties, payload).then((response) => {
        if (response !== false) {
          if (response?.status === 200) {
            console.log("response.data dddddd >>>>", response.data);
            appProperties.licenseObj = response.data;

            let responseData = response?.data;
            if (responseData && (responseData?.status === "failed" || responseData?.status === "success_with_failure")) {
              if (responseData?.status === "success_with_failure") {
                setResponseObj(response);
                setCallSavedUserTable(true);
                setIsDrawerOpen(false);
                successNotifyWithDescription("Add Users", "User added! They're now part of the team." + responseData?.successUserList);
              }
              if (responseData?.existingEmailIdsList) {
                failureNotificationWithDuration15Seconds("User Already Part of Team", responseData?.existingEmailIdsList);
              }
              if (responseData?.failedUsersList) {
                failurePricingNotificationWithBtn(
                  "User Limit Reached",
                  responseData?.failedUsersList + " Contact assist@hellosend.com to increase your limit. ",
                  "Manage Subscription",
                  appProperties
                );
              }
              setIsLoading(false);
            } else {
              setResponseObj(response);
              setCallSavedUserTable(true);
              setIsDrawerOpen(false);
              setIsLoading(false);

              successNotifyWithDescription("Add Users", "User added! They're now part of the team.");
            }
          }
        } else {
          setIsLoading(false);
          var userLimit = `User limit reached. Contact ${ASSIST_MAIL} to add more users.`;
          failureNotificationWithLink(userLimit, `${ASSIST_MAIL}`, `mailto:${ASSIST_MAIL}`);
        }
      });
    } else {
      setIsLoading(false);
      failureNotification("Add user", "At least one user must be selected to add.");
    }
  };

  const handleClose = () => {
    setDrawerOpen(false);
    setIsDrawerOpen(false);
  };

  const handleUserRemoval = (user) => {
    setUsersList(usersList.filter((t) => t.key !== user.key));
  };

  const handleUser = (selectedUser, selectedOption) => {
    // emails.push(value.key + "::" + value.value);
    if (selectedUser && !userListArray.has(selectedUser)) {
      const addUser = [
        ...usersList,
        {
          key: usersList.length + 1,
          value: selectedUser,
          userId: selectedOption.key,
        },
      ];
      setUsersList(addUser);
    }
  };

  return (
    <>
      <Drawer
        className="userDrawer"
        title={"Add user"}
        open={drawerOpen}
        onClose={handleClose}
        placement="right"
        width={"30rem"}
        height={"100rem"}
      >
        <div className="userModal">
          <Row>
            <Col xs={24} md={24}>
              Users
              <div className="mt-2">
                <Select
                  style={{
                    width: "100% !important",
                    height: "3.5rem",
                    backgroundColor: "#fafafb",
                  }}
                  placeholder="Select users"
                  className="selectUser focus-border rounded hs-bg-off-white"
                  value={selectedUser}
                  onChange={handleUser}
                  bordered={false}
                  loading={loadingUserResponse}
                >
                  {users?.map((user, index) => {
                    return (
                      <Select.Option key={user.id} value={user.email}>
                        {user.firstName} ({user.email})
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
            </Col>
          </Row>
          <div className="mt-3">Selected User</div>
          <div
            className="usersEmail"
            style={{
              backgroundColor: "#fafafb",
              marginTop: "1rem",
              padding: ".5rem",
              height: "20rem",
              overflow: "auto",
            }}
          >
            {usersList.map((user) => (
              <Tag
                key={user.key}
                style={{
                  color: "#6A707E",
                  borderColor: "#90A0B7",
                  backgroundColor: "#F9F9FB",
                  borderRadius: "5px",
                }}
                className="p-1 m-2"
                onClose={() => handleClose(user)}
              >
                <Row>
                  <Col>{user.value}</Col>
                  <Col onClick={() => handleUserRemoval(user)} className="actionIconsSprite closeIcon cursor-pointer ms-2" />
                </Row>
              </Tag>
            ))}
          </div>
          <br />
          <Divider />
          <br />
          <Col span={24} className="d-flex align-items-center justify-content-end">
            <Button className="templateAdd" type="" size="large" onClick={addUser} loading={loading}>
              Add
            </Button>
          </Col>
        </div>
      </Drawer>
    </>
  );
});
